import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { environment } from "../../../environments/environment";
import { map, of, tap } from "rxjs";
import { IAddress } from "../../models/address";
import { IPlaceAutoComplete } from "../../models/placeAutoComplete";

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    private teamAdvanceAddressApi = environment.teamAdvanceAddressApi;
    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), params: new HttpParams() };
    constructor(private _httpClient: HttpClient) {
    }

    public addresses = signal<IAddress[]>([]);
    public address = signal<IAddress>({placeId: '', streetAddress1:'', streetAddress2:'', state:'', city:'',  zipCode: '', zipCodeExtension:'', location: { longitude:0, latitude:0 }})

    placeAutoComplete(input: string) {
        let params = new HttpParams()
            .set('input', input)
        this.options.params = params
        return this._httpClient.get<IPlaceAutoComplete[]>(this.teamAdvanceAddressApi + "v1/place/autocomplete?", this.options);
    }

    placeGeoCode(geocode: { input: string, type: string }) {
        let params = new HttpParams()
            .set('input', geocode.input)
            .append('type', geocode.type)
        this.options.params = params
        return this._httpClient.get<IAddress[]>(this.teamAdvanceAddressApi + "v1/place/geocode", this.options)
    }

    setAddresses(addresses: IAddress[]){
    }

} 