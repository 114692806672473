import { input } from "@angular/core";
import { createActionGroup, props } from "@ngrx/store";
import { IAddress } from "../../models/address";
import { IPlaceAutoComplete } from "../../models/placeAutoComplete";

export const AddressAutoCompleteActions = createActionGroup(
    {
        source: 'Address Form',
        events: {
            'Address AutoComplete': props<{searchTerm:string}>(),
            'Address GeoCode': props<{geocode:{input:string, type:string}}>()
        }
    }
)

export const AddressAutoCompleteApiActions = createActionGroup({
    source: 'Google Place API',
    events:{
        'Address AutoCompleteSuccess': props<{placeAutoComplete: IPlaceAutoComplete[]}>(),
        'Address AutoCompleteFail': props<{message:string}>(),
        'Address GeoCodeSuccess': props<{address:IAddress[]}>(),
        'Address GeoCodeFail': props<{message:string}>()
        }
})